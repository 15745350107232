






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsFormSection',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) label?: string
}
