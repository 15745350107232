var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.customFieldId ? _vm.$t('lists.customFieldModal.edit') : _vm.$t('lists.customFieldModal.add'))+" campo ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('lists.customFieldModal.close'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.customFieldId ? _vm.$t('lists.customFieldModal.saveEdit') : _vm.$t('lists.customFieldModal.saveAdd'))+" ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"customFieldForm"},[(!_vm.customFieldId)?_c('ValidationProvider',{attrs:{"vid":"defaultCf","name":"Scegli un campo","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"label":_vm.$t('lists.customFieldModal.chooseField'),"error":errors.length > 0,"errorMessage":errors[0],"value":_vm.defaultField,"options":_vm.defaultFields},on:{"input":_vm.setDefaultField}})]}}],null,false,1707473283)}):_vm._e(),(_vm.defaultField === 'custom')?_c('ValidationProvider',{attrs:{"vid":"defaultCf","name":"Nome","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-6",attrs:{"label":_vm.$t('lists.customFieldModal.name'),"error":errors.length > 0,"errorMessage":errors[0],"placeholder":_vm.$t('lists.customFieldModal.insertName')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,false,554085775)}):_vm._e(),_c('div',{staticClass:"vs-mb-6"},[(_vm.defaultField)?_c('VsSelect',{staticClass:"vs-mb-2",attrs:{"disabled":_vm.isCustomTypeDisabled,"label":_vm.$t('lists.customFieldModal.type'),"value":_vm.customType,"options":_vm.customTypes},on:{"input":_vm.setCustomType}}):_vm._e(),(_vm.selectableType && _vm.defaultField)?_c('div',[_vm._l((_vm.customFieldOptions),function(option,index){return _c('div',{key:index,staticClass:"vs-flex vs-items-start vs-gap-2 vs-mb-1"},[(_vm.customType !== 'BooleanCheckbox')?_c('ValidationProvider',{attrs:{"vid":'optionValue' + index,"name":"Valore","rules":{
                            required: true,
                            onlyLettersNumbersSpacesDashUnderscore: true,
                            startOrEndWithSpace: true,
                            unique: _vm.customFieldOptions.map(function (el, i) { return '@optionValue' + i; }).filter(function (el, i) { return i !== index; })
                        },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1",attrs:{"errorMessage":errors[0],"error":errors.length > 0,"labelHidden":"","placeholder":_vm.$t('lists.customFieldModal.value')},model:{value:(option.value),callback:function ($$v) {_vm.$set(option, "value", $$v)},expression:"option.value"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"vid":'optionLabel' + index,"name":"Etichetta","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1",attrs:{"errorMessage":errors[0],"error":errors.length > 0,"labelHidden":"","placeholder":_vm.$t('lists.customFieldModal.tag')},model:{value:(option.label),callback:function ($$v) {_vm.$set(option, "label", $$v)},expression:"option.label"}})]}}],null,true)}),_c('VsButton',{staticClass:"vs-flex-none",attrs:{"aspect":"secondary","disabled":_vm.customFieldOptions.length === 1,"variant":"icon","iconName":"trash"},on:{"click":function($event){return _vm.customFieldOptions.splice(index, 1)}}})],1)}),_c('VsButton',{staticClass:"vs-mt-2",attrs:{"size":"small","iconName":"plus","aspect":"secondary"},on:{"click":function($event){return _vm.customFieldOptions.push({
                        label: '',
                        value: '',
                        selected: false,
                    })}}},[_vm._v(" "+_vm._s(_vm.$t('lists.customFieldModal.addOption'))+" ")])],2):_vm._e(),(_vm.customType === 'BooleanCheckbox' && _vm.defaultField)?_c('div',[_c('ValidationProvider',{attrs:{"vid":'optionLabel',"name":"Etichetta","rules":"required|startOrEndWithSpace","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1",attrs:{"errorMessage":errors[0],"error":errors.length > 0,"labelHidden":"","placeholder":_vm.$t('lists.customFieldModal.tag')},model:{value:(_vm.customFieldOptions[0].label),callback:function ($$v) {_vm.$set(_vm.customFieldOptions[0], "label", $$v)},expression:"customFieldOptions[0].label"}})]}}],null,false,3638613609)})],1):_vm._e()],1),(_vm.defaultField)?_c('VsCheckbox',{staticClass:"vs-mb-6",attrs:{"text":_vm.$t('lists.customFieldModal.requiredField'),"labelHidden":"","disabled":_vm.options.unique},model:{value:(_vm.required),callback:function ($$v) {_vm.required=$$v},expression:"required"}}):_vm._e(),(!_vm.advanced && _vm.defaultField)?_c('VsButton',{staticClass:"vs-mb-6",attrs:{"iconName":'chevron-right',"variant":"link"},on:{"click":_vm.toggleAdvanced}},[_vm._v(" "+_vm._s(_vm.advanced ? _vm.$t('lists.customFieldModal.hideAdvanced') : _vm.$t('lists.customFieldModal.advanced'))+" ")]):_vm._e(),(_vm.advanced)?_c('CollapseTransition',[_c('div',[_c('VsCheckbox',{staticClass:"vs-mb-6",attrs:{"text":_vm.$t('lists.customFieldModal.showOnSubscribersArea'),"disabled":_vm.options.unique,"labelHidden":"","value":_vm.visibility,"input-value":"Public"},on:{"input":function($event){$event ? _vm.visibility = 'Public': _vm.visibility = 'User only'}}}),(_vm.customType === 'Single line')?_c('VsSelect',{staticClass:"vs-mb-6",attrs:{"label":_vm.$t('lists.customFieldModal.validation'),"disabled":_vm.defaultField !== 'custom',"options":_vm.validations},model:{value:(_vm.validation),callback:function ($$v) {_vm.validation=$$v},expression:"validation"}}):_vm._e(),(_vm.customType === 'Single line' && _vm.isCustomValidation)?_c('div',[_c('ValidationProvider',{staticClass:"vs-mb-6",attrs:{"vid":'validationRule',"name":"Regola","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{attrs:{"errorMessage":errors[0],"error":errors.length > 0,"label":_vm.$t('lists.customFieldModal.rule')},model:{value:(_vm.rule),callback:function ($$v) {_vm.rule=$$v},expression:"rule"}})]}}],null,false,2179751724)})],1):_vm._e(),_c('VsLabel',[_vm._v(_vm._s(_vm.$t('lists.customFieldModal.defaultValue')))]),_c('VsCustomFieldGenerator',{staticClass:"vs-mb-6",attrs:{"labelHidden":"","disabled":_vm.defaultField !== 'custom' || _vm.options.unique,"customField":_vm.currentGeneratedCustomFieldDefaultValue},model:{value:(_vm.defaultValue),callback:function ($$v) {_vm.defaultValue=$$v},expression:"defaultValue"}}),_c('ValidationProvider',{attrs:{"vid":'placeholder',"name":"Placeholder","rules":"alpha_num","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-6",attrs:{"label":"Placeholder","errorMessage":errors[0],"error":errors.length > 0,"value":_vm.placeholder.toUpperCase()},on:{"input":function($event){_vm.placeholder = $event.toUpperCase()}},model:{value:(_vm.placeholder),callback:function ($$v) {_vm.placeholder=$$v},expression:"placeholder"}})]}}],null,false,3684364658)})],1)]):_vm._e()],1),((_vm.initialCustomType !== '' && _vm.initialCustomType !== _vm.customType) || (_vm.initialValidation !== '' && _vm.initialValidation !== _vm.validation))?_c('VsNotification',{attrs:{"aspect":"warning","message":_vm.$t('lists.customFieldModal.validationNotification')}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }