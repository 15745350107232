








































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getCustomFieldTag } from '@/utils/personalizations'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'VsCustomFieldCard',
})
export default class extends Vue {
    @Prop() customField!: any
    @Prop() aspect!: any
    @Prop() variant!: any

    get customFieldCode () {
        return getCustomFieldTag(this.customField)
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    private copyToClipboard (text: string) {
        try {
            copyToClipboard(text)
            this.$root.$vsToast({
                heading: 'Codice copiato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (error) {
            this.$root.$vsToast({
                heading: 'Errore durante la copia del codice',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }
}
