



























































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsCustomFieldGenerator from '@/modules/lists/components/VsCustomFieldGenerator/Index.vue'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import {
    CustomFieldTypeEnum,
    CustomFieldValidationMethodEnum,
    CustomField,
} from '@/utils/customFields'

export interface CustomFieldsOptions {
    unique: boolean
    showEmail: boolean
    showMobile: boolean
}

@Component({
    name: 'VsCustomFieldsModal',
    components: {
        CollapseTransition,
        VsCustomFieldGenerator,
    },
})
export default class extends Vue {
    public loading = false
    private open = false
    private options: CustomFieldsOptions = {
        unique: false,
        showEmail: false,
        showMobile: false,
    }

    private customFieldId: any = null
    private defaultField: null | string = null
    private required = false
    private visibility: 'Public' | 'User only' = 'Public'
    private initialCustomType = ''
    private initialValidation = ''
    private customType = CustomFieldTypeEnum.singleLine
    private advanced = false
    private validation = CustomFieldValidationMethodEnum.disabled
    private placeholder: any = ''
    private defaultValue: any = ''
    private rule = ''
    private name = ''
    private customFieldOptions: any[] = [{
        label: '',
        value: '',
        selected: false,
    }]

    $refs: any

    get selectableType () {
        return this.customType === CustomFieldTypeEnum.multipleChoise ||
            this.customType === CustomFieldTypeEnum.dropdown ||
            this.customType === CustomFieldTypeEnum.checkboxes
    }

    get isCustomValidation () {
        return this.validation === CustomFieldValidationMethodEnum.custom
    }

    get customTypes (): any[] {
        return [
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.singleLine}`),
                value: CustomFieldTypeEnum.singleLine,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.paragraphText}`),
                value: CustomFieldTypeEnum.paragraphText,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.multipleChoise}`),
                value: CustomFieldTypeEnum.multipleChoise,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.dropdown}`),
                value: CustomFieldTypeEnum.dropdown,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.checkboxes}`),
                value: CustomFieldTypeEnum.checkboxes,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.booleanCheckbox}`),
                value: CustomFieldTypeEnum.booleanCheckbox,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.dateField}`),
                value: CustomFieldTypeEnum.dateField,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.timeField}`),
                value: CustomFieldTypeEnum.timeField,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.hiddenField}`),
                value: CustomFieldTypeEnum.hiddenField,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.email}`),
                value: CustomFieldTypeEnum.email,
                disabled: !this.options.showEmail,
            },
            {
                label: this.$t(`lists.customfieldType.${CustomFieldTypeEnum.mobile}`),
                value: CustomFieldTypeEnum.mobile,
                disabled: !this.options.showMobile,
            },
        ]
    }

    get validations (): any[] {
        return [
            {
                label: 'Nessuna',
                value: CustomFieldValidationMethodEnum.disabled,
            },
            {
                label: 'Numerico',
                value: CustomFieldValidationMethodEnum.numbers,
            },
            {
                label: 'Lettere',
                value: CustomFieldValidationMethodEnum.letters,
            },
            {
                label: 'Indirizzo email',
                value: CustomFieldValidationMethodEnum.emailAddress,
            },
            {
                label: 'Telefono cellulare principale',
                value: CustomFieldValidationMethodEnum.mobile,
            },
            {
                label: 'URL',
                value: CustomFieldValidationMethodEnum.url,
            },
            {
                label: 'Custom',
                value: CustomFieldValidationMethodEnum.custom,
            },
        ]
    }

    get defaultFields (): any[] {
        return [
            {
                label: 'Nominativo',
                value: 'name',
            },
            {
                label: 'Nome azienda',
                value: 'company',
            },
            {
                label: 'Identificativo fiscale',
                value: 'code',
            },
            {
                label: 'Partita iva',
                value: 'piva',
            },
            {
                label: 'Indirizzo',
                value: 'address',
            },
            {
                label: 'Personalizzato',
                value: 'custom',
            },
        ]
    }

    get currentGeneratedCustomField () {
        const customField: any = {
            name: this.name,
            type: this.customType,
            default_value: this.defaultValue,
            visibility: this.visibility,
            global: false,
            validation: {
                method: this.validation,
                rule: this.rule,
                required: this.required,
                unique: this.options.unique,
            },
            options: this.customFieldOptions,
            placeholder: this.placeholder,
        }
        return customField
    }

    get currentGeneratedCustomFieldDefaultValue () {
        return {
            name: 'Valore di default',
            type: this.customType,
            default_value: this.defaultValue,
            visibility: this.visibility,
            global: false,
            validation: {
                method: this.validation,
                rule: this.rule,
                required: false,
                unique: this.options.unique,
            },
            field_options: this.customFieldOptions,
        }
    }

    get isCustomTypeDisabled () {
        return this.defaultField !== 'custom' || (this.initialCustomType !== '' && (this.initialCustomType === CustomFieldTypeEnum.email || this.initialCustomType === CustomFieldTypeEnum.mobile))
    }

    private async submit () {
        const valid = await this.$refs.customFieldForm.validate()
        const hasDuplicatedOptionsValue = this.hasDuplicatedOptionsValue()
        if (!valid || hasDuplicatedOptionsValue) return
        if (this.customFieldId) {
            this.$emit('custom-field-updated', {
                id: this.customFieldId,
                data: this.currentGeneratedCustomField,
            })
        } else {
            this.$emit('custom-field-generated', this.currentGeneratedCustomField)
        }
    }

    get customFieldsOptionsValues () {
        return this.customFieldOptions.map(el => el.value)
    }

    private hasDuplicatedOptionsValue () {
        const duplicateValues = this.customFieldsOptionsValues.filter((item, index) => this.customFieldsOptionsValues.indexOf(item) !== index)

        const hasDuplicated = duplicateValues.length > 0
        if (hasDuplicated) {
            for (const index in this.customFieldOptions) {
                if (duplicateValues.includes(this.customFieldOptions[index].value)) {
                    this.$refs.customFieldForm.setErrors({
                        ['optionValue' + index]: ['i valori devono essere univoci'],
                    })
                }
            }
        }
        return hasDuplicated
    }

    private toggleAdvanced () {
        this.advanced = !this.advanced
    }

    public openModal (options: CustomFieldsOptions, customField?: CustomField) {
        this.initialCustomType = ''
        this.initialValidation = ''
        this.customFieldId = null
        this.advanced = false
        this.defaultField = null
        this.rule = ''
        this.placeholder = ''
        this.visibility = 'Public'
        this.options = options
        this.required = options.unique
        if (customField) {
            customField = JSON.parse(JSON.stringify(customField)) as CustomField
            this.initialCustomType = customField.type
            this.initialValidation = customField.validation.method
            this.customFieldId = customField.id
            this.defaultField = 'custom'
            this.required = customField.validation.required
            this.customType = customField.type
            this.validation = customField.validation.method
            this.rule = customField.validation.rule
            this.defaultValue = customField.default_value
            this.name = customField.name
            this.customFieldOptions = customField.field_options
            this.placeholder = customField.placeholder
        }
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    private setDefaultField (defaultField: string) {
        switch (defaultField) {
            case 'name':
                this.name = 'Nominativo'
                break
            case 'company':
                this.name = 'Nome azienda'
                break
            case 'code':
                this.name = 'Identificativo fiscale'
                break
            case 'piva':
                this.name = 'Partita iva'
                break
            case 'address':
                this.name = 'Indirizzo'
                break
            case 'custom':
                this.name = ''
                break
        }
        this.defaultValue = ''
        this.validation = CustomFieldValidationMethodEnum.disabled
        this.customType = CustomFieldTypeEnum.singleLine
        this.defaultField = defaultField
    }

    private setCustomType (type: CustomFieldTypeEnum) {
        switch (type) {
            case CustomFieldTypeEnum.dropdown:
            case CustomFieldTypeEnum.checkboxes:
            case CustomFieldTypeEnum.multipleChoise:
                this.validation = CustomFieldValidationMethodEnum.disabled
                this.customFieldOptions = [{
                    label: '',
                    value: '',
                    selected: false,
                }]
                break
            case CustomFieldTypeEnum.booleanCheckbox:
                this.validation = CustomFieldValidationMethodEnum.disabled
                this.customFieldOptions = [{
                    label: '',
                    value: true,
                    selected: false,
                }]
                break
            case CustomFieldTypeEnum.dateField:
                this.validation = CustomFieldValidationMethodEnum.date
                break
            case CustomFieldTypeEnum.timeField:
                this.validation = CustomFieldValidationMethodEnum.time
                break
            case CustomFieldTypeEnum.email:
                this.validation = CustomFieldValidationMethodEnum.emailAddress
                break
            case CustomFieldTypeEnum.mobile:
                this.validation = CustomFieldValidationMethodEnum.mobile
                break
            default:
                this.validation = CustomFieldValidationMethodEnum.disabled
                break
        }
        this.defaultValue = type === CustomFieldTypeEnum.checkboxes ? [] : ''
        this.customType = type
    }
}
